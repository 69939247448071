import { default as forgot_45passwordYiAlXkLIh0Meta } from "/var/www/html/pages/auth/forgot-password.vue?macro=true";
import { default as loginRDJc48mj2VMeta } from "/var/www/html/pages/auth/login.vue?macro=true";
import { default as registertTmjjrADHMMeta } from "/var/www/html/pages/auth/register.vue?macro=true";
import { default as cguxfYZE3MLX3Meta } from "/var/www/html/pages/cgu.vue?macro=true";
import { default as indexIgO5MnNvfXMeta } from "/var/www/html/pages/dashboard/index.vue?macro=true";
import { default as mes_45alertesTpKG1j1FsVMeta } from "/var/www/html/pages/dashboard/mes-alertes.vue?macro=true";
import { default as mes_45demandes_45de_45trajetsSEvkeqQBnMMeta } from "/var/www/html/pages/dashboard/mes-demandes-de-trajets.vue?macro=true";
import { default as mes_45trajets_45achetes0yBuuQq2o8Meta } from "/var/www/html/pages/dashboard/mes-trajets-achetes.vue?macro=true";
import { default as mes_45trajets_45publies8ImTEhZ8aKMeta } from "/var/www/html/pages/dashboard/mes-trajets-publies.vue?macro=true";
import { default as dashboardihhmfDpFogMeta } from "/var/www/html/pages/dashboard.vue?macro=true";
import { default as guide3pqSUlDv04Meta } from "/var/www/html/pages/guide.vue?macro=true";
import { default as indexUbNeHw0dYYMeta } from "/var/www/html/pages/index.vue?macro=true";
import { default as less_45demandess6Vpxg9PCiMeta } from "/var/www/html/pages/less-demandes.vue?macro=true";
import { default as mentions_45legalesGMoNE98OGmMeta } from "/var/www/html/pages/mentions-legales.vue?macro=true";
import { default as nos_45services7tX1Z8Hf4pMeta } from "/var/www/html/pages/nos-services.vue?macro=true";
import { default as notre_45concept8cbMbUcv6DMeta } from "/var/www/html/pages/notre-concept.vue?macro=true";
import { default as politique_45cookie6rmTD5BSOtMeta } from "/var/www/html/pages/politique-cookie.vue?macro=true";
import { default as politique_45de_45confidentialiteeAieU6p5WXMeta } from "/var/www/html/pages/politique-de-confidentialite.vue?macro=true";
import { default as publier_45un_45trajetAYlDJDeHWQMeta } from "/var/www/html/pages/publier-un-trajet.vue?macro=true";
import { default as rechercher_45un_45trajetrGff1FFNntMeta } from "/var/www/html/pages/rechercher-un-trajet.vue?macro=true";
import { default as resultats1VSIyPQ0DHMeta } from "/var/www/html/pages/resultats.vue?macro=true";
import { default as votre_45un_45trajetclxCcaicMJMeta } from "/var/www/html/pages/votre-un-trajet.vue?macro=true";
export default [
  {
    name: forgot_45passwordYiAlXkLIh0Meta?.name ?? "auth-forgot-password",
    path: forgot_45passwordYiAlXkLIh0Meta?.path ?? "/auth/forgot-password",
    meta: forgot_45passwordYiAlXkLIh0Meta || {},
    alias: forgot_45passwordYiAlXkLIh0Meta?.alias || [],
    redirect: forgot_45passwordYiAlXkLIh0Meta?.redirect || undefined,
    component: () => import("/var/www/html/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: loginRDJc48mj2VMeta?.name ?? "auth-login",
    path: loginRDJc48mj2VMeta?.path ?? "/auth/login",
    meta: loginRDJc48mj2VMeta || {},
    alias: loginRDJc48mj2VMeta?.alias || [],
    redirect: loginRDJc48mj2VMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: registertTmjjrADHMMeta?.name ?? "auth-register",
    path: registertTmjjrADHMMeta?.path ?? "/auth/register",
    meta: registertTmjjrADHMMeta || {},
    alias: registertTmjjrADHMMeta?.alias || [],
    redirect: registertTmjjrADHMMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: cguxfYZE3MLX3Meta?.name ?? "cgu",
    path: cguxfYZE3MLX3Meta?.path ?? "/cgu",
    meta: cguxfYZE3MLX3Meta || {},
    alias: cguxfYZE3MLX3Meta?.alias || [],
    redirect: cguxfYZE3MLX3Meta?.redirect || undefined,
    component: () => import("/var/www/html/pages/cgu.vue").then(m => m.default || m)
  },
  {
    path: dashboardihhmfDpFogMeta?.path ?? "/dashboard",
    children: [
  {
    name: indexIgO5MnNvfXMeta?.name ?? "dashboard",
    path: indexIgO5MnNvfXMeta?.path ?? "",
    meta: indexIgO5MnNvfXMeta || {},
    alias: indexIgO5MnNvfXMeta?.alias || [],
    redirect: indexIgO5MnNvfXMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: mes_45alertesTpKG1j1FsVMeta?.name ?? "dashboard-mes-alertes",
    path: mes_45alertesTpKG1j1FsVMeta?.path ?? "mes-alertes",
    meta: mes_45alertesTpKG1j1FsVMeta || {},
    alias: mes_45alertesTpKG1j1FsVMeta?.alias || [],
    redirect: mes_45alertesTpKG1j1FsVMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/dashboard/mes-alertes.vue").then(m => m.default || m)
  },
  {
    name: mes_45demandes_45de_45trajetsSEvkeqQBnMMeta?.name ?? "dashboard-mes-demandes-de-trajets",
    path: mes_45demandes_45de_45trajetsSEvkeqQBnMMeta?.path ?? "mes-demandes-de-trajets",
    meta: mes_45demandes_45de_45trajetsSEvkeqQBnMMeta || {},
    alias: mes_45demandes_45de_45trajetsSEvkeqQBnMMeta?.alias || [],
    redirect: mes_45demandes_45de_45trajetsSEvkeqQBnMMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/dashboard/mes-demandes-de-trajets.vue").then(m => m.default || m)
  },
  {
    name: mes_45trajets_45achetes0yBuuQq2o8Meta?.name ?? "dashboard-mes-trajets-achetes",
    path: mes_45trajets_45achetes0yBuuQq2o8Meta?.path ?? "mes-trajets-achetes",
    meta: mes_45trajets_45achetes0yBuuQq2o8Meta || {},
    alias: mes_45trajets_45achetes0yBuuQq2o8Meta?.alias || [],
    redirect: mes_45trajets_45achetes0yBuuQq2o8Meta?.redirect || undefined,
    component: () => import("/var/www/html/pages/dashboard/mes-trajets-achetes.vue").then(m => m.default || m)
  },
  {
    name: mes_45trajets_45publies8ImTEhZ8aKMeta?.name ?? "dashboard-mes-trajets-publies",
    path: mes_45trajets_45publies8ImTEhZ8aKMeta?.path ?? "mes-trajets-publies",
    meta: mes_45trajets_45publies8ImTEhZ8aKMeta || {},
    alias: mes_45trajets_45publies8ImTEhZ8aKMeta?.alias || [],
    redirect: mes_45trajets_45publies8ImTEhZ8aKMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/dashboard/mes-trajets-publies.vue").then(m => m.default || m)
  }
],
    name: dashboardihhmfDpFogMeta?.name ?? undefined,
    meta: dashboardihhmfDpFogMeta || {},
    alias: dashboardihhmfDpFogMeta?.alias || [],
    redirect: dashboardihhmfDpFogMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: guide3pqSUlDv04Meta?.name ?? "guide",
    path: guide3pqSUlDv04Meta?.path ?? "/guide",
    meta: guide3pqSUlDv04Meta || {},
    alias: guide3pqSUlDv04Meta?.alias || [],
    redirect: guide3pqSUlDv04Meta?.redirect || undefined,
    component: () => import("/var/www/html/pages/guide.vue").then(m => m.default || m)
  },
  {
    name: indexUbNeHw0dYYMeta?.name ?? "index",
    path: indexUbNeHw0dYYMeta?.path ?? "/",
    meta: indexUbNeHw0dYYMeta || {},
    alias: indexUbNeHw0dYYMeta?.alias || [],
    redirect: indexUbNeHw0dYYMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/index.vue").then(m => m.default || m)
  },
  {
    name: less_45demandess6Vpxg9PCiMeta?.name ?? "less-demandes",
    path: less_45demandess6Vpxg9PCiMeta?.path ?? "/less-demandes",
    meta: less_45demandess6Vpxg9PCiMeta || {},
    alias: less_45demandess6Vpxg9PCiMeta?.alias || [],
    redirect: less_45demandess6Vpxg9PCiMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/less-demandes.vue").then(m => m.default || m)
  },
  {
    name: mentions_45legalesGMoNE98OGmMeta?.name ?? "mentions-legales",
    path: mentions_45legalesGMoNE98OGmMeta?.path ?? "/mentions-legales",
    meta: mentions_45legalesGMoNE98OGmMeta || {},
    alias: mentions_45legalesGMoNE98OGmMeta?.alias || [],
    redirect: mentions_45legalesGMoNE98OGmMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: nos_45services7tX1Z8Hf4pMeta?.name ?? "nos-services",
    path: nos_45services7tX1Z8Hf4pMeta?.path ?? "/nos-services",
    meta: nos_45services7tX1Z8Hf4pMeta || {},
    alias: nos_45services7tX1Z8Hf4pMeta?.alias || [],
    redirect: nos_45services7tX1Z8Hf4pMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/nos-services.vue").then(m => m.default || m)
  },
  {
    name: notre_45concept8cbMbUcv6DMeta?.name ?? "notre-concept",
    path: notre_45concept8cbMbUcv6DMeta?.path ?? "/notre-concept",
    meta: notre_45concept8cbMbUcv6DMeta || {},
    alias: notre_45concept8cbMbUcv6DMeta?.alias || [],
    redirect: notre_45concept8cbMbUcv6DMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/notre-concept.vue").then(m => m.default || m)
  },
  {
    name: politique_45cookie6rmTD5BSOtMeta?.name ?? "politique-cookie",
    path: politique_45cookie6rmTD5BSOtMeta?.path ?? "/politique-cookie",
    meta: politique_45cookie6rmTD5BSOtMeta || {},
    alias: politique_45cookie6rmTD5BSOtMeta?.alias || [],
    redirect: politique_45cookie6rmTD5BSOtMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/politique-cookie.vue").then(m => m.default || m)
  },
  {
    name: politique_45de_45confidentialiteeAieU6p5WXMeta?.name ?? "politique-de-confidentialite",
    path: politique_45de_45confidentialiteeAieU6p5WXMeta?.path ?? "/politique-de-confidentialite",
    meta: politique_45de_45confidentialiteeAieU6p5WXMeta || {},
    alias: politique_45de_45confidentialiteeAieU6p5WXMeta?.alias || [],
    redirect: politique_45de_45confidentialiteeAieU6p5WXMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/politique-de-confidentialite.vue").then(m => m.default || m)
  },
  {
    name: publier_45un_45trajetAYlDJDeHWQMeta?.name ?? "publier-un-trajet",
    path: publier_45un_45trajetAYlDJDeHWQMeta?.path ?? "/publier-un-trajet",
    meta: publier_45un_45trajetAYlDJDeHWQMeta || {},
    alias: publier_45un_45trajetAYlDJDeHWQMeta?.alias || [],
    redirect: publier_45un_45trajetAYlDJDeHWQMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/publier-un-trajet.vue").then(m => m.default || m)
  },
  {
    name: rechercher_45un_45trajetrGff1FFNntMeta?.name ?? "rechercher-un-trajet",
    path: rechercher_45un_45trajetrGff1FFNntMeta?.path ?? "/rechercher-un-trajet",
    meta: rechercher_45un_45trajetrGff1FFNntMeta || {},
    alias: rechercher_45un_45trajetrGff1FFNntMeta?.alias || [],
    redirect: rechercher_45un_45trajetrGff1FFNntMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/rechercher-un-trajet.vue").then(m => m.default || m)
  },
  {
    name: resultats1VSIyPQ0DHMeta?.name ?? "resultats",
    path: resultats1VSIyPQ0DHMeta?.path ?? "/resultats",
    meta: resultats1VSIyPQ0DHMeta || {},
    alias: resultats1VSIyPQ0DHMeta?.alias || [],
    redirect: resultats1VSIyPQ0DHMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/resultats.vue").then(m => m.default || m)
  },
  {
    name: votre_45un_45trajetclxCcaicMJMeta?.name ?? "votre-un-trajet",
    path: votre_45un_45trajetclxCcaicMJMeta?.path ?? "/votre-un-trajet",
    meta: votre_45un_45trajetclxCcaicMJMeta || {},
    alias: votre_45un_45trajetclxCcaicMJMeta?.alias || [],
    redirect: votre_45un_45trajetclxCcaicMJMeta?.redirect || undefined,
    component: () => import("/var/www/html/pages/votre-un-trajet.vue").then(m => m.default || m)
  }
]